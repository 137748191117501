<template>
  <div class="wxpaybox">
    <img class="wxpayimg" src="@/assets/image/wxpay.jpg" alt="" />
    <button v-if="wxBrowser" class="btn" @click="clickBtn">
      已完成支付,查看报告
    </button>
    <div v-else class="noWxBrowser">
      <button class="btn" @click="clickBtn">已完成支付,查看报告</button>
      <button class="btn" @click="immediatePayment">立即支付</button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { tradePay, latestOrderId, genOrderNo } from '../api/getUserInfo';
export default {
  name: 'wxpay',
  data() {
    return {
      payInfo: null,
      // true 是微信浏览器
      wxBrowser: true,
      // true 是支付宝浏览器
      isAlipay: false,
      zfbFlag: null,
    };
  },
  created() {
    this.payInfo = this.$route.query;
  },
  async mounted() {
    var ua = navigator.userAgent.toLowerCase();
    //支付宝环境下
    this.isAlipay = ua.indexOf('alipay') !== -1;
    this.zfbFlag = this.isAlipay ? 1 : null;
    if (ua.indexOf('micromessenger') == -1) {
      this.wxBrowser = false;
      // 不是微信浏览器
      let isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      try {
        let result = await genOrderNo(this.payInfo);
        if (result.data.code == 200) {
          if (isMobile) {
            // 是手机
            this.payInfo.orderNo = result.data.data;
            this.payInfo.payType = 2;
            this.payInfo.zfbFlag = this.zfbFlag;
            this.pay(this.payInfo);
          } else {
            // 是网页
            this.payInfo.orderNo = result.data.data;
            this.payInfo.payType = 1;
            this.payInfo.zfbFlag = this.zfbFlag;
            this.pay(this.payInfo);
          }
        }
      } catch (error) {
        Toast(error);
      }
    } else {
      this.wxBrowser = true;
      genOrderNo(this.payInfo);
    }
  },
  methods: {
    pay(data) {
      tradePay(data)
        .then((res) => {
          if (res.data.code == 200) {
            const payType = res.data.data.payType;
            //普通支付宝支付
            if (payType == 1) {
              const div = document.createElement('div');
              /* 此处form就是后台返回接收到的数据 */
              div.innerHTML = res.data.data.link;
              document.body.appendChild(div);
              document.forms[0].submit();
            } else if (payType == 4) {
              //聚合易支付
              window.location.href = res.data.data.link;
            } else if (payType == 5 || payType == 6) {
              //银盛支付 第三方链接
              if (res.data.data.link) {
                //银盛支付 第三方链接
                const details = {
                  orderNo: this.payInfo.orderNo,
                  userId: this.$route.query.userId,
                  // payMoney: this.userInfo.payMoney,
                  link: res.data.data.link,
                };
                if (this.payInfo.payType == 1) {
                  //网页
                  // console.log(encodeURIComponent(JSON.stringify(details)));
                  this.$router.push({
                    path: '/guidPay',
                    query: {
                      details: encodeURIComponent(JSON.stringify(details)),
                    },
                  });
                } else if (this.payInfo.payType == 2) {
                  //手机
                  const userAgent = navigator.userAgent.toLowerCase();
                  //支付宝环境下
                  if (userAgent.indexOf('alipay') !== -1) {
                    //在此项目业务逻辑中 其实并不会跳转到该页面 一下代码不会执行
                    const { href } = this.$router.resolve({
                      path: '/guidPay',
                      query: {
                        details: encodeURIComponent(JSON.stringify(details)),
                      },
                    });
                    window.open(href, '_blank');
                  } else {
                    //普通浏览器
                    window.location.href = res.data.data.link;
                  }
                }
              } else {
                Toast('支付链接有误');
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 按钮
    clickBtn() {
      latestOrderId({ userId: this.$route.query.userId }).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.porduct == 1) {
            this.$router.push({
              path: '/success',
              query: {
                orderNo: res.data.data.orderNo,
                cid: res.data.data.cid,
                templateId: res.data.data.templateId,
              },
            });
          } else {
            // 2 是天创的接口 显示天创的页面
            // res.data.data.porduct==2
            this.$router.push({
              path: '/prosperity',
              query: {
                orderNo: res.data.data.orderNo,
                cid: res.data.data.cid,
                templateId: res.data.data.templateId,
              },
            });
          }
        } else {
          Toast('您还没有支付,请完成支付');
        }
      });
    },
    // 立即支付
    async immediatePayment() {
      var ua = navigator.userAgent.toLowerCase();
      // 不是微信浏览器
      if (ua.indexOf('micromessenger') == -1) {
        let isMobile = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        try {
          let result = await genOrderNo(this.payInfo);
          if (result.data.code == 200) {
            if (isMobile) {
              // 是手机
              this.payInfo.orderNo = result.data.data;
              this.payInfo.payType = 2;
              this.payInfo.zfbFlag = this.zfbFlag;
              this.pay(this.payInfo);
            } else {
              // 是网页
              this.payInfo.orderNo = result.data.data;
              this.payInfo.payType = 1;
              this.payInfo.zfbFlag = this.zfbFlag;
              this.pay(this.payInfo);
            }
          } else {
            Toast(result.data.msg);
          }
        } catch (error) {
          Toast(error);
        }
      } else {
        genOrderNo(this.payInfo);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wxpaybox {
  width: 100%;
  height: auto;
  text-align: center;
}
.wxpayimg {
  width: 100%;
}
.btn {
  width: 80%;
  height: 78px;
  background: #1989fa;
  border: none;
  margin-top: 20px;
  color: #fff;
  font-size: 30px;
}
.noWxBrowser {
  width: 100%;
  height: 78px;
  display: flex;
  justify-content: space-around;
  button {
    width: 40%;
    height: 78px;
    background: #1989fa;
    border: none;
    margin-top: 20px;
    color: #fff;
    font-size: 26px;
    border-radius: 10px;
  }
}
</style>
